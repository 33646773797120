var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{staticClass:"mt-6 py-2 px-0 mx-6 border-radius-xl toolbar-content-padding-y-none",class:_vm.navbarFixed
        ? 'position-sticky blur shadow-blur top-1 z-index-sticky py-2'
        : '',attrs:{"color":_vm.background,"height":"auto","flat":""}},[_c('v-row',{staticClass:"py-1"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('div',[_c('h6',{staticClass:"text-h6 font-weight-bolder text-typo mb-0"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.name))+" ")])]),(!_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"drawer-toggler pa-5 ms-6 cursor-pointer",class:{ active: _vm.togglerActive },attrs:{"id":"burgerBtn"},on:{"click":_vm.minifyDrawer}},[_c('div',{staticClass:"drawer-toggler-inner"},[_c('i',{staticClass:"drawer-toggler-line bg-body"}),_c('i',{staticClass:"drawer-toggler-line bg-body"}),_c('i',{staticClass:"drawer-toggler-line bg-body"})])]):_vm._e()]),_c('v-col',{staticClass:"d-flex align-center",class:_vm.$vuetify.rtl ? 'text-sm-left' : 'text-sm-right',attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"ms-auto"}),(_vm.$vuetify.breakpoint.mobile)?_c('v-btn',{staticClass:"font-weight-600 text-capitalize drawer-toggler py-3 px-0 rounded-sm",class:{
            'btn-dark-toggler-hover': !_vm.hasBg,
            'btn-toggler-hover': _vm.hasBg,
            active: _vm.togglerActive,
          },attrs:{"elevation":"0","ripple":false,"height":"43","color":"transparent"},on:{"click":_vm.drawerClose}},[_c('div',{staticClass:"drawer-toggler-inner"},[_c('i',{staticClass:"drawer-toggler-line text-body"}),_c('i',{staticClass:"drawer-toggler-line text-body"}),_c('i',{staticClass:"drawer-toggler-line text-body"})])]):_vm._e(),_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":"","offset-x":"","min-width":"300","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-body",class:{ 'btn-dark-hover': !_vm.hasBg, 'btn-hover': _vm.hasBg },attrs:{"id":"btnAccountCircle","icon":"","ripple":false,"color":_vm.linkColor,"small":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"material-icons-round",attrs:{"size":"20"}},[_vm._v(" account_circle ")])],1)]}}])},[_c('v-list',{staticClass:"pa-3"},_vm._l((_vm.dropdown),function(item,i){return _c('v-list-item',{key:i,staticClass:"pa-4 list-item-hover-active d-flex align-items-center py-1 my-1 border-radius-md",attrs:{"id":"logoutBtn","to":item.link,"link":""}},[_c('v-icon',{staticClass:"material-icons-round text-body",attrs:{"size":"20"}},[_vm._v(_vm._s(item.icon))]),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',{staticClass:"text-body-2 ls-0 text-typo font-weight-600 mb-0"},[_c('v-row',[_c('v-col',[_c('h6',{staticClass:"text-sm font-weight-normal ms-2 text-typo",domProps:{"innerHTML":_vm._s(item.title)}},[_vm._v(" "+_vm._s(item.title)+" ")])])],1)],1)],1)],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }