import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import ProfileLayout from "../views/Layout/ProfileLayout";
import AuthBasicLayout from "../views/Layout/AuthBasicLayout";
import { i18n} from "@/main";
import login from "@/views/Login.vue";
// Dashboard pages
const Companys = () => import("../views/Companys.vue");
const Importer = () => import("../views/Importer.vue");
const Reinsurance = () => import("../views/Reinsurance.vue");
const Employees = () => import("../views/Employees.vue");
const Pensions = () => import("../views/Pensions.vue");
const Profile = () => import("../views/Profile.vue");
const Settings = () => import("../views/Settings.vue");
const Dashboard = () => import("../views/Dashboard.vue");
const Login = () => import("../views/Login.vue");
const PensionsPlan = () => import("../views/PensionsPlan.vue");
const PensionContributions = () => import("../views/PensionContributions.vue");
const PensionContributionsRate = () => import("../views/PensionContributionsRate.vue");
const Bbg = () => import("../views/Bbg.vue");
const Calculations = () => import("../views/Calculations.vue");
const TransformationTableNew = () => import("../views/TransformationTableNew.vue");
const TransformationTableOld = () => import("../views/TransformationTableOld.vue");
const VersorgungsbausteinVO2000 = () => import("../views/VersorgungsbausteinVO2000.vue");

const SupportFonds = () => import("../views/SupportFonds.vue");



Vue.use(VueRouter);

const routes = [
  {
    path: "/user",
    name: "Profile Layout",
    redirect: "/",
    component: ProfileLayout,
    children: [
      {
        path: "profile",
        name: "Profile",
        component: Profile,
        meta: {
          groupName: "Profile",
        },
      },
    ]
  },
  {
    path: "/auth",
    name: "Login Layout",
    redirect: "/",
    component: AuthBasicLayout,
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
        meta: {
          groupName: "Login",
        },
      },
    ]
  },
  {
    path: "/",
    name: "",
    redirect: "/",
    component: DashboardLayout,
    children: [
      {
        path: "/",
        name: "dashBoard",
        component: Dashboard,
        meta: {
          requiredAuth: true,
          groupName: "Dashboard",
        },
      },
      {
        path: "company",
        name: "company",
        component: Companys,
        meta: {
          requiredAuth: true,
          groupName: "Dashboard",
        },
      },
      {
        path: "importer",
        name: "importer",
        component: Importer,
        meta: {
          requiredAuth: true,
          groupName: "Dashboard",
        },
      },
      {
        path: "pensionsPlan",
        name: "pensionScheme",
        component: PensionsPlan,
        meta: {
          requiredAuth: true,
          onlyAccessFor: "pension",
          groupName: "Dashboard",
        },
      },
      {
        path: "pensionContributions",
        name: "pensionContribution",
        component: PensionContributions,
        meta: {
          requiredAuth: true,
          groupName: "Dashboard",
        },
      },
      {
        path: "pensionContributionsRate",
        name: "pensionContributionRate",
        component: PensionContributionsRate,
        meta: {
          requiredAuth: true,
          groupName: "Dashboard",
        },
      },
      {
        path: "employees",
        name: "employees",
        component: Employees,
        meta: {
          requiredAuth: true,
          groupName: "Dashboard",
        },
      },
      {
        path: "pensions",
        name: "pensions",
        component: Pensions,
        meta: {
          requiredAuth: true,
          groupName: "Dashboard",
        },
      },
      {
        path: "reinsurance",
        name: "Rückdeckung",
        component: Reinsurance,
        meta: {
          requiredAuth: true,
          onlyAccessFor: "kasse",
          groupName: "Dashboard",
        },
      },
      {
        path: "bbg",
        name: "Bbg",
        component: Bbg,
        meta: {
          groupName: "Dashboard",
        },
      },
      {
        path: "calculations",
        name: "calculations",
        component: Calculations,
        meta: {
          requiredAuth: true,
          groupName: "Dashboard",
        },
      },
      {
        path: "settings",
        name: "settings",
        component: Settings,
        meta: {
          requiredAuth: true,
          groupName: "Dashboard",
        },
      },
      {
        path: "transformationTableNew",
        name: "Transformationstabelle neu",
        component: TransformationTableNew,
        meta: {
          requiredAuth: true,
          groupName: "Dashboard",
        },
      },
      {
        path: "transformationTableOld",
        name: "Transformationstabelle alt",
        component: TransformationTableOld,
        meta: {
          requiredAuth: true,
          groupName: "Dashboard",
        },
      },
      {
        path: "versorgungsbausteinVO2000",
        name: "Versorgungsbaustein VO 2000",
        component: VersorgungsbausteinVO2000,
        meta: {
          requiredAuth: true,
          groupName: "Dashboard",
        },
      },
    ],
  },
];


const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {

  //check user role
  const userRoles = JSON.parse(localStorage.getItem('userRoles'))
  if (to.meta.onlyAccessFor !== undefined && userRoles.includes(to.meta.onlyAccessFor)) {
    next()
  } else if (to.meta.onlyAccessFor !== undefined && !userRoles.includes(to.meta.onlyAccessFor)) {
    next({name:'Login'})
  } else {
    next()
  }
  //check selectedCompanyId
  let selectedCompanyID = JSON.parse(localStorage.getItem('selectedCompany')).id;
  if(selectedCompanyID !== 1) {
    if(to.path === '/PensionContributions' || to.path === '/PensionContributionsRate' || to.name === '/Settings') {
      next({name:'Login'})
    }
  }


  //Is der Lang_Token gesetzt
  if (localStorage.getItem('LANG_TOKEN')) {
    try {
      i18n.locale = localStorage.getItem('LANG_TOKEN')
      next()
    } catch (e) {
      console.log(e)
      localStorage.setItem('LANG_TOKEN', 'de')
      next()
    }
  } else {
    localStorage.setItem('LANG_TOKEN', 'de')
    next()
  }
})

export default router;
