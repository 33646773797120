<template>
  <v-navigation-drawer
      width="100%"
      height="calc(100% - 2rem)"
      app
      :expand-on-hover="mini"
      :value="drawer"
      :right="$vuetify.rtl"
      class="my-4 ms-4 border-radius-xl"
      :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'"
      :data-color="sidebarColor"
      :data-theme="sidebarTheme">
    <template>
      <v-list-item class="mt-5 mb-2">
        <v-img
            id="ifm_Logo"
            src="@/assets/logo/ifm-logo-weiss-300x133.png"
            alt="ifm_Logo"
            class=""></v-img>
      </v-list-item>
    </template>
    <!--    <hr-->
    <!--      class="horizontal mb-0 lineHeight"-->
    <!--      :class="sidebarTheme == 'dark' ? 'light' : 'dark'"-->
    <!--      v-if="selectedCompany" />-->

    <div
        v-for="(item, i) in superAdminItems"
        :key="item.title + i">
      <v-list-item
          link
          :to="item.link"
          :id="item.title + 'MenuBtn'"
          class="pb-1 mx-2 no-default-hover py-2"
          :ripple="false"
          active-class="item-active"
          v-if="!item.external">
        <v-list-item-icon class="me-2 align-center">
          <i class="material-icons-round opacity-10">{{ item.action }}</i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="ms-1">{{ $t(item.title) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
          link
          :to="item.link"
          :id="item.title + 'MenuBtn'"
          class="pb-1 mx-2 no-default-hover py-2"
          :ripple="false"
          active-class="item-active"
          v-if="item.external">
        <v-list-item-icon class="me-2 align-center">
          <i class="material-icons-round opacity-10">{{ item.action }}</i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="ms-1">{{ $t(item.title) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
    <hr
        class="horizontal mb-0 lineHeight"
        :class="sidebarTheme == 'dark' ? 'light' : 'dark'"/>

    <v-list-item-content>
      <v-list
          nav
          dense>
        <v-list-group
            id="rollBtnCompany"
            :ripple="false"
            :key="companys.title"
            v-model="companys.active"
            append-icon="fas fa-angle-down"
            active-class="item-active">
          <template v-slot:activator>
            <v-list-item-icon class="me-2 align-center">
              <i class="material-icons-round opacity-10">apartment</i>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="ms-2 ps-1 font-weight-light">
                {{ selectedCompany ? selectedCompany.name : $t(companys.title) }}
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
              :ripple="false"
              class="mb-1 no-default-hover px-0"
              v-for="(child, idx) in companys.items"
              :class="child.items ? 'has-children' : ''"
              :key="child.title + idx"
              @click="selectCompany(child)">
            <div class="w-100 d-flex align-center pa-2 border-radius-lg">
              <span
                  class="v-list-item-mini"
                  v-text="child.prefix"></span>

              <v-list-item-content
                  class="ms-6 ps-1"
                  v-if="!child.items">
                <v-list-item-title
                    v-text="child.title"
                    @click="listClose($event)"></v-list-item-title>
              </v-list-item-content>
            </div>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-list-item-content>

    <hr
        class="horizontal mb-0 lineHeight"
        :class="sidebarTheme == 'dark' ? 'light' : 'dark'"/>

    <div
        v-for="(item, i) in items"
        :key="i"
        v-if="selectedCompany">
      <v-list-item
          link
          :to="item.link"
          :id="item.title + 'MenuBtn'"
          class="pb-1 mx-2 no-default-hover py-2"
          :ripple="false"
          active-class="item-active"
          v-if="!item.external && userRoles.includes(item.role) || item.role === undefined">
        <v-list-item-icon class="me-2 align-center">
          <i class="material-icons-round opacity-10">{{ item.action }}</i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="ms-1">{{ $t(item.title) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
          link
          :to="item.link"
          :id="item.title + 'MenuBtn'"
          class="pb-1 mx-2 no-default-hover py-2"
          :ripple="false"
          active-class="item-active"
          v-if="item.external">
        <v-list-item-icon class="me-2 align-center">
          <i class="material-icons-round opacity-10">{{ item.action }}</i>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
              v-text="item.title"
              class="ms-1"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
    <v-list-item-content v-if="selectedCompany">
      <v-list
          nav
          dense>
        <v-list-group
            :ripple="false"
            append-icon="fas fa-angle-down"
            active-class="item-active">
          <template v-slot:activator>
            <v-list-item-icon class="me-2 align-center">
              <i class="material-icons-round opacity-10">table_rows</i>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="ms-2 ps-1 font-weight-light text-break">
                {{ $t('pension1') }} <br/>
                {{ $t('modules2') }}
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
              link
              :to="item.link"
              :id="item.title + 'MenuBtn'"
              :ripple="false"
              class="mb-1 no-default-hover px-0"
              v-for="(item, idx) in pensionPlans"
              :key="item.title + idx"
              v-if="!item.external">

            <div class="w-100 d-flex align-center pa-2 border-radius-lg">
              <v-list-item-content class="ms-6 ps-1">
                <v-list-item-title class="ms-1">{{ $t(item.title) }}</v-list-item-title>
              </v-list-item-content>
            </div>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-list-item-content>

    <hr
        class="horizontal mb-0 lineHeight"
        :class="sidebarTheme == 'dark' ? 'light' : 'dark'"/>
    <div v-if="userRoles.includes('pension')">
      <v-list-item-content class="pt-0">
        <v-list
            nav
            dense>
          <v-list-group
              id="rollBtnSettings"
              :ripple="false"
              append-icon="fas fa-angle-down"
              active-class="item-active">
            <template v-slot:activator>
              <v-list-item-icon class="me-2 align-center">
                <i class="material-icons-round opacity-10">settings</i>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="ms-2 ps-1 font-weight-light">
                  {{ $t('settings') }}
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                link
                :to="item.link"
                :ripple="false"
                class="mb-1 no-default-hover px-0"
                v-for="(item, idx) in settings"
                :key="item.title + idx"
                :id="item.title">
              <div class="w-100 d-flex align-center pa-2 border-radius-lg">
                <!--              <span class="v-list-item-mini" v-text="child.title"></span>-->

                <!--              <v-list-item-content>-->
                <!--                <v-list-item-title-->
                <!--                    v-text="child.title"-->
                <!--                ></v-list-item-title>-->
                <!--              </v-list-item-content>-->

                <v-list-item-content class="ms-6 ps-1">
                  <v-list-item-title class="ms-1">{{ $t(item.title) }}</v-list-item-title>
                </v-list-item-content>
              </div>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-list-item-content>
    </div>
  </v-navigation-drawer>
</template>
<script>
import {apiGet} from '@/api'

export default {
  name: 'drawer',
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: 'success',
    },
    sidebarTheme: {
      type: String,
      default: 'dark',
    },
  },
  data: () => ({
    selectedCompany: JSON.parse(localStorage.getItem('selectedCompany')),
    mini: false,
    userRoles: [],
    togglerActive: false,
    thirdLevelSimple: ['Third level menu', 'Just another link', 'One last link'],
    userInfo: [
      {
        active: false,
        title: 'Profile',
        items: [
          {
            title: 'My Profile',
            prefix: 'MP',
            link: '/user/profile',
          },
          {
            title: 'Settings',
            prefix: 'S',
            link: '/settings',
          },
          {
            title: 'Logout',
            prefix: 'L',
            link: '/auth/login',
          },
        ],
      },
    ],
    companys: {
      active: false,
      title: 'company',
      items: [],
    },

    settings: [
      {
        action: 'money',
        link: '/bbg',
        title: 'Bbg',
        external: false,
      },
    ],
    pensionPlans: [
      {
        action: 'upload',
        link: '/pensionsPlan',
        title: 'pensionPlan',
        external: false,
      },
    ],

    superAdminItems: [
      {
        action: 'dashboard',
        link: '/',
        title: 'dashBoard',
        external: false,
      },
      {
        action: 'business',
        title: 'company',
        prefix: 'C',
        link: '/company',
      },
    ],
    items: [],
  }),
  mounted() {
    this.userRoles = JSON.parse(localStorage.getItem('userRoles'))

    let selectedCompanyID = JSON.parse(localStorage.getItem('selectedCompany')).id;

    if (selectedCompanyID !== 5) {
      this.items.push(
          {
            action: 'upload',
            link: '/importer',
            title: 'importer',
            external: false,
          },
          {
            action: 'calculate',
            link: '/calculations',
            title: 'calculations',
            external: false,
          },
          {
            action: 'group',
            link: `/employees`,
            title: 'employees',
            prefix: 'C',
          },
          {
            action: 'assignment',
            link: `/pensions`,
            title: 'pensions',
            prefix: 'C',
          },

      );
    }

    if(selectedCompanyID === 1) {
      this.pensionPlans.push(
            {
              action: 'upload',
              link: '/pensionContributions',
              title: 'pensionContribution',
              external: false,
            },
            {
              action: 'upload',
              link: '/pensionContributionsRate',
              title: 'pensionContributionRate',
              external: false,
            },
          {
            action: 'upload',
            link: '/transformationTableNew',
            title: 'Transformationstabelle neu',
            external: false,
          },
          {
            action: 'upload',
            link: '/transformationTableOld',
            title: 'Transformationstabelle alt',
            external: false,
          },
          {
            action: 'upload',
            link: '/VersorgungsbausteinVO2000',
            title: 'Versorgungsbaustein VO 2000',
            external: false,
          },
      );
    } else {
      this.items.push(
          {
            action: 'shop',
            link: '/reinsurance',
            title: 'Rückdeckung',
            external: false,
          },
      )
    }
  },
  async created() {
    let companys = await apiGet('/company?userId=' + localStorage.getItem('userIdent'))
    this.companys.items = companys.map((x) => ({
      title: x.name,
      prefix: x.name.slice(0, 2),
      ...x,
    }))
  },
  methods: {
    notMusterfirma(){
      console.log("Company id: "+ this.selectedCompany.id)
      return this.selectedCompany.id === 0
    },
    selectCompany(company) {
      localStorage.setItem('selectedCompany', JSON.stringify(company))
      if(company.id === 1 && this.$route.name === 'Rückdeckung') {
        this.$router.push('/')
      } else {
        this.$router.go()
      }
    },
    listClose(event) {
      let items
      let headers
      let groups
      let currentEl

      if (document.querySelectorAll('.mb-0.v-list-item.v-list-item--link.item-active')) {
        items = document.querySelectorAll('.mb-0.v-list-item.v-list-item--link.item-active')
      }

      if (
          document.querySelectorAll(
              '.mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active'
          )
      ) {
        headers = document.querySelectorAll(
            '.mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active'
        )
      }

      if (
          document.querySelectorAll(
              '.mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active'
          )
      ) {
        groups = document.querySelectorAll(
            '.mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active'
        )
      }

      if (
          event.target.closest(
              '.mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group'
          )
      ) {
        currentEl = event.target.closest(
            '.mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group'
        )
      }

      if (items != null) {
        for (var i = 0; i < items.length; i++) {
          items[i].classList.remove('item-active')
        }
      }

      if (headers != null) {
        for (var j = 0; j < headers.length; j++) {
          headers[j].classList.remove('v-list-item--active', 'item-active', 'primary--text')
          headers[j].setAttribute('aria-expanded', false)
        }
      }

      if (groups != null) {
        for (var k = 0; k < groups.length; k++) {
          groups[k].classList.remove('v-list-group--active', 'primary--text')
        }
      }

      if (event.target.closest('.mb-0.v-list-item.v-list-item--link')) {
        event.target.closest('.mb-0.v-list-item.v-list-item--link').classList.add('item-active')
      }

      if (currentEl != null) {
        currentEl
            .querySelector('.v-list-group__header')
            .classList.add('v-list-item--active', 'item-active')
      }
    },
  },
}
</script>
<style>
.lineHeight {
  height: 3px !important;
}
</style>
